// Generated by Framer (0961f6c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./m3JrpigCD-0.js";

const cycleOrder = ["FUiJjsAcX", "d17NKmrxj"];

const serializationHash = "framer-hs6Uq"

const variantClassNames = {d17NKmrxj: "framer-v-1rdxt3c", FUiJjsAcX: "framer-v-y2hvo"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {U_5FWB8bN: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "FUiJjsAcX", Smaller: "d17NKmrxj"}

const getProps = ({change, height, id, width, ...props}) => { return {...props, GH2nYU8v6: change ?? props.GH2nYU8v6 ?? {src: "https://framerusercontent.com/images/BmbxGrMpa6gRJfc5I327SMKzNaQ.svg"}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "FUiJjsAcX"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;change?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, GH2nYU8v6, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "FUiJjsAcX", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-y2hvo", className, classNames)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"FUiJjsAcX"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgb(230, 234, 244)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgb(249, 251, 252)", borderBottomLeftRadius: 16, borderBottomRightRadius: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16, ...style}} {...addPropertyOverrides({d17NKmrxj: {"data-framer-name": "Smaller"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fit", pixelHeight: 50, pixelWidth: 50, sizes: "56px", ...toResponsiveImage(GH2nYU8v6), ...{ positionX: "center", positionY: "center" }}} className={"framer-2xkpvm"} layoutDependency={layoutDependency} layoutId={"g2qP3U8u3"}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-hs6Uq.framer-trv9f9, .framer-hs6Uq .framer-trv9f9 { display: block; }", ".framer-hs6Uq.framer-y2hvo { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 121px; justify-content: center; max-width: 114px; overflow: hidden; padding: 0px; position: relative; width: 114px; will-change: var(--framer-will-change-override, transform); }", ".framer-hs6Uq .framer-2xkpvm { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 56px); overflow: hidden; position: relative; width: 56px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-hs6Uq.framer-y2hvo { gap: 0px; } .framer-hs6Uq.framer-y2hvo > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-hs6Uq.framer-y2hvo > :first-child { margin-left: 0px; } .framer-hs6Uq.framer-y2hvo > :last-child { margin-right: 0px; } }", ".framer-hs6Uq.framer-v-1rdxt3c .framer-2xkpvm { height: 46%; width: var(--framer-aspect-ratio-supported, 56px); }", ".framer-hs6Uq[data-border=\"true\"]::after, .framer-hs6Uq [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 121
 * @framerIntrinsicWidth 114
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"],"constraints":[null,"114px",null,null]},"d17NKmrxj":{"layout":["fixed","fixed"],"constraints":[null,"114px",null,null]}}}
 * @framerVariables {"GH2nYU8v6":"change"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerm3JrpigCD: React.ComponentType<Props> = withCSS(Component, css, "framer-hs6Uq") as typeof Component;
export default Framerm3JrpigCD;

Framerm3JrpigCD.displayName = "Logo-block";

Framerm3JrpigCD.defaultProps = {height: 121, width: 114};

addPropertyControls(Framerm3JrpigCD, {variant: {options: ["FUiJjsAcX", "d17NKmrxj"], optionTitles: ["Variant 1", "Smaller"], title: "Variant", type: ControlType.Enum}, GH2nYU8v6: {__defaultAssetReference: "data:framer/asset-reference,BmbxGrMpa6gRJfc5I327SMKzNaQ.svg?originalFilename=Logo%3DDexign%2C+Logo+type%3DLogi+icon%2C+Color%3DDefault.svg&preferredSize=auto", title: "Change", type: ControlType.ResponsiveImage}} as any)

addFonts(Framerm3JrpigCD, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})